import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Alert,
} from "reactstrap";

import { fetchCourse, updateCourseModal, editCourse, deleteCourse, deleteCourseReset } from 'redux/Courses/CoursesActions';
import Swal from 'sweetalert2'
import { connect } from 'react-redux';
import DataTable from "react-data-table-component";
const CoursesTable = (props) => {

    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState([]);


    useEffect(() => {
        
        const data = {
            route : "",
            userId : props.userDetails.userId,
            handle:props.userDetails.handle
        }
        
        dispatch(fetchCourse(data))
        
        
    }, [])
    
    useEffect(() => {

        console.log(props.fetchCourses.courses)
        
        // setList(props.fetchCourses.courses.slice(0, 5))
        setList(props.fetchCourses.courses)
        setFilter(props.fetchCourses.courses)
        
        
    }, [props.fetchCourses])

    if(props.deleteCourse.course.length && typeof props.deleteCourse.error === "undefined"){
        
        Swal.fire({
            title: 'Deleted!',
            text: 'Successfully deleted Course!',
            icon : 'success',
            timer: 3000      
          }
        )
        dispatch(deleteCourseReset());
        
        
      }

    else if(typeof props.deleteCourse.error !== "undefined"){
        
        Swal.fire({
            title:'Failed!',
            text:'Failed to delete Course!',
            icon :'error',
            timer: 3000 
            }
        )            
        dispatch(deleteCourseReset());
    }

    const onClickEditCourse = (e,courseId) => {
        e.preventDefault();
        dispatch(editCourse({
            status : true,
            courseId : courseId
        }));

        dispatch(updateCourseModal(!props.updateCourseModalStatus.status));
    }

    const onClickDeleteCourse = (e,courseId, userId, handle) => {
        e.preventDefault();
        

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCourse({
                    docId : courseId,
                    userId : userId,
                    handle : handle
                }));

            }
          })
    }

    const showNext = (e,item) => {
        e.preventDefault();
        
        if(list.length !== 0) {
            dispatch(fetchCourse({route:"Next" , item:item.item, userId:props.userDetails.userId, handle:props.userDetails.handle}))
            setList(props.fetchCourses.courses)
            setPage(page + 1)
        } 
    };

    const showPrevious = ( e,item ) => {
        e.preventDefault();
        if(list.length !== 0) {
            dispatch(fetchCourse({route:"Previous", item:item.item, userId:props.userDetails.userId, handle:props.userDetails.handle}))
            setList(props.fetchCourses.courses)
            setPage(page - 1)
        } 
    };

    const columns = [
        {
            name: 'Course Name',
            sortable: "true",
            selector: row => row.courseName,
        },
        {
            name: 'Course No',
            sortable: "true",
            selector: row => row.courseNumber,
        },
        {
            name: 'Terms',
            sortable: "true",
            selector: row => row.term,
        },
        {
            name: 'Grade',
            sortable: "true",
            selector: row => row.grade,
        },
        {
            name: 'Credits',
            sortable: "true",
            selector: row => row.credits,
        },
        {
            name: 'BCPM',
            sortable: "true",
            selector: row =>  (row.bcpm) ? "Yes" : "No",
        },
        {
            name: 'School Year',
            sortable: "true",
            selector: row => row.section,
        },
        // {
        //     name: 'Year',
        //     sortable: "true",
        //     selector: row => row.year ? row.year : <span className='text-danger font-weight-bold'>Update Required</span>,
        // },
        {
            name: 'Action',
            center: 'true',
            width: '200px',
            cell: (row) => (
                <>
                    {
                        props.adminUser.admin ?
                        <a className="btn btn-sm btn-primary" onClick={(e) => onClickEditCourse(e,row.courseId)}><i className="fa fa-eye"></i> View</a>
                    :
                        <>
                            <a className="btn btn-sm btn-primary" onClick={(e) => onClickEditCourse(e,row.courseId)}><i className="fa fa-pen"></i> Edit</a>
                            <a className="btn btn-sm btn-danger" onClick={(e) => onClickDeleteCourse(e,row.courseId,row.userId, row.handle)}><i className="fa fa-trash"></i> Delete</a>
                        </>
                    }
                </>
                      
            )
        }
       
     ];

    const tableHeaderstyle = {
        headCells: {
          style: {
            fontWeight: "bold",
            fontSize: "14px",
            // backgroundColor: "#ccc",
          },
        },
      };

      useEffect(() => {
        const result = list.filter((item) => {
          return (
            item.courseName.toLowerCase().match(search.toLocaleLowerCase()) ||
            item.courseNumber.toLowerCase().match(search.toLocaleLowerCase()) ||
            item.section.toLowerCase().match(search.toLocaleLowerCase()) ||
            item.term.toLowerCase().match(search.toLocaleLowerCase()) 

          );
        });
        setFilter(result);
      }, [search]);
    
  return (
    <>
        {  list.length ? (
          
        <DataTable
            customStyles={tableHeaderstyle}
            columns={columns}
            data={filter}
            pagination
            wrap
            selectableRowsHighlight
            highlightOnHover
            subHeader
            subHeaderAlign="left"
            subHeaderComponent={
              <input
                type="text"
                className="w-50 form-control "
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            }
            
        />
        ) : (
          <Alert color="primary">No data available .</Alert>
        )}
    </>
    // <>
    //     <Table className="align-items-center table-flush" responsive>
    //         <thead className="thead-light">
    //             <tr>
    //                 <th scope="col">COURSE NAME</th>
    //                 <th scope="col">COURSE NUMBER</th>
    //                 <th scope="col">TERM</th>
    //                 <th scope="col">GRADE</th>
    //                 <th scope="col">CREDITS</th>
    //                 <th scope="col">BCPM</th>
    //                 <th scope="col">SCHOOL YEAR</th>
    //                 <th scope="col">ACTION</th>
    //             </tr>
    //         </thead>
    //         <tbody>
                
                
    //             {
    //                 list.map((val) => {
                    
    //                 return <tr key = {val.courseId}>
    //                         <td scope="row">
    //                             {val.courseName}
    //                         </td>
    //                         <td>
    //                             {val.courseNumber}
    //                         </td>
    //                         <td>
    //                             {val.term}
    //                         </td>
    //                         <td>
    //                             {val.grade}
    //                         </td>
    //                         <td>
    //                             {val.credits}
    //                         </td>
    //                         <td>
    //                             {(val.bcpm) ? "Yes" : "No"}
    //                         </td>
    //                         <td>
    //                              {val.section}
    //                         </td>
    //                         <td className="">
    //                             {props.adminUser.admin ?
    //                                 <a className="btn btn-sm btn-primary" onClick={(e) => onClickEditCourse(e,val.courseId)}><i className="fa fa-eye"></i> View</a>
    //                             :
    //                                 <>
    //                                     <a className="btn btn-sm btn-primary" onClick={(e) => onClickEditCourse(e,val.courseId)}><i className="fa fa-pen"></i> Edit</a>
    //                                     <a className="btn btn-sm btn-danger" onClick={(e) => onClickDeleteCourse(e,val.courseId,val.userId, val.handle)}><i className="fa fa-trash"></i> Delete</a>
    //                                 </>
    //                             }
    //                             {/* <UncontrolledDropdown>
    //                             <DropdownToggle
    //                                 className="btn-icon-only text-light"
                                   
    //                                 role="button"
    //                                 size="sm"
    //                                 color=""
    //                                 onClick={(e) => e.preventDefault()}
    //                             >
    //                                 <i className="fas fa-ellipsis-v" />
    //                             </DropdownToggle>
    //                             <DropdownMenu className="dropdown-menu-arrow" right>
    //                                 <DropdownItem
                                   
    //                                 onClick={(e) => onClickEditCourse(e,val.courseId)}
    //                                 >
    //                                 {props.adminUser.admin ? "View" : "Edit"}
    //                                 </DropdownItem>
    //                                 <DropdownItem
                                   
    //                                 onClick={(e) => onClickDeleteCourse(e,val.courseId,val.userId, val.handle)}
    //                                 >
    //                                 Delete
    //                                 </DropdownItem>
    //                             </DropdownMenu>
    //                             </UncontrolledDropdown> */}
    //                         </td>
    //                     </tr>
    //                 })

    //             }
                    
                    
                
    //         </tbody>
    //     </Table>
    //     <CardFooter className="py-4">
    //         <nav aria-label="...">
    //             <Pagination
    //             className="pagination justify-content-end mb-0"
    //             listClassName="justify-content-end mb-0"
    //             >
    //             <PaginationItem className={page === 1 ? 'disabled' : ''}>
    //                 <PaginationLink
                   
    //                 onClick={(e) => showPrevious(e,{ item: list[0] })}
    //                 tabIndex="-1"
    //                 >
    //                 <i className="fas fa-angle-left" />
    //                 <span className="sr-only">Previous</span>
    //                 </PaginationLink>
    //             </PaginationItem>
    //             <PaginationItem className={list.length < 5 ? 'disabled' : ''}>
    //                 <PaginationLink
                   
    //                 onClick={(e) => showNext(e,{ item: list[list.length - 1] })}
    //                 >
    //                 <i className="fas fa-angle-right" />
    //                 <span className="sr-only">Next</span>
    //                 </PaginationLink>
    //             </PaginationItem>
    //             </Pagination>
    //         </nav>
    //     </CardFooter>
    // </>
  )
}

const mapStateToProps = state => {
    return {
        fetchCourses : state.fetchCourses,
        updateCourseModalStatus : state.updateCourseModalStatus,
        deleteCourse : state.deleteCourse,
        userDetails : state.loginAuth.userDetails,
        adminUser : state.adminUser
    }
  }

export default connect(mapStateToProps)(CoursesTable)