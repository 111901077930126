import React, { useEffect, useState } from 'react';
import { useStripe, PaymentElement, useElements } from '@stripe/react-stripe-js';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchPaymentRecord, createTpcCoursePayment, createTpcCoursePaymentReset, buyTutoringHours } from 'redux/Payments/PaymentsActions';
import {
    Card,
    Container,
    Row,
    Col,
    CardBody,
    CardHeader
} from "reactstrap";
import Swal from 'sweetalert2';
import BillingDetailsForm from './BillingDetailsForm';
import { fetchTpcCourses } from 'redux/ManageTpcCourses/ManageTpcCoursesActions'

const BuyTpcCourseForm = (props) => {

    const history = useHistory()

    const addZeroes = num => num.toLocaleString("en",{minimumFractionDigits: 2})

    //Convert to string
    const strTypePrice = addZeroes(Number(props.course.coursePrice))

    //Convert to integer
    const tpcprice = Number(props.course.coursePrice)
   

    const options = {
        layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: true,   
        },
        terms : {
            card:"never"
        },
        fields :{
            billingDetails :{
                name:"never"
            }
        }
    }

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    
    const handleError = (error) => {
        setLoading(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setLoading(true);

        const {error: submitError} = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        let amount = tpcprice
        dispatch(createTpcCoursePayment({amount: amount, user: props.userDetails.handle, docid: props.course.tpcCourseId}))
    }

    useEffect(async () => {
        if(props.createTpcCoursePayment.createtpccoursepayment.length){
            // // Trigger form validation and wallet collection
            const {error: submitError} = await elements.submit();

            if (submitError) {
                handleError(submitError);
                return;
            }
            
            const {client_secret: clientSecret} = await props.createTpcCoursePayment.createtpccoursepayment[0];

            // Confirm the PaymentIntent using the details collected by the Payment Element
            const {error} = await stripe.confirmPayment({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: 'https://example.com/order/123/complete',
                },
                redirect : "if_required"
            });


            if (error) {
                // This point is only reached if there's an immediate error when confirming the Intent.
                // Show the error to your customer (for example, "payment details incomplete").
                handleError(error);

                Swal.fire({
                    title:'Failed!',
                    text: error.message,
                    icon :'error',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    
                    dispatch(createTpcCoursePaymentReset());
                    
                })

            } else {
                
                // Your customer is redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer is redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                Swal.fire({
                    title:'Success!',
                    text:'Purchase successful!',
                    icon :'success',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    dispatch(fetchPaymentRecord({table: 'tpcCourses', user: props.userDetails.handle}))
                    dispatch(fetchTpcCourses({visibility: "published" }))
    
                    dispatch(buyTutoringHours(!props.buyTutoringHoursModalStatus.status));
                    dispatch(createTpcCoursePaymentReset());


                    history.push('/client/tpc-courses')
                    // window.location.reload();

                })
            }
        }
    }, [props.createTpcCoursePayment])

  return (
    <form onSubmit={handleSubmit}>

        <p className='h2 text-dark'>Billing</p>
        <BillingDetailsForm/>
        <br/>
        <p className='h2 text-dark'>Payment</p>
        <PaymentElement options={options}  />
        <br/>
        <button className='btn btn-primary btn-block' disabled={!stripe || loading}>Pay </button>
        
    </form>
  )
}

const mapStateToProps = state => {
    return {
        createTpcCoursePayment : state.createTpcCoursePayment,
        userDetails : state.loginAuth.userDetails,
        buyTutoringHoursModalStatus : state.buyTutoringHoursModalStatus,
        fetchTpcCourses : state.fetchTpcCourses,
        fetchPaymentRecord: state.fetchPaymentRecord,
    }
}

export default connect(mapStateToProps)(BuyTpcCourseForm)